/* eslint-disable react-hooks/exhaustive-deps */
import { useRef, useState, useEffect } from 'react';
import { useForm, Controller, useFieldArray } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { getListRoomStyle, updateProfile } from '../../helper/function';
import { formatMoney } from '../../helper/lib';
import Select from 'react-select';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

const ProfileDesigner = ({
  isEditProfile,
  dataProfile,
  benefits,
  getUserData,
}) => {
  const navigate = useNavigate();
  const [selectedAvailableStatus, setSelectedAvailableStatus] = useState('');
  const [styleSelected, setStyleSelected] = useState('');
  const [styles, setStyles] = useState([]);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);

  const availableStatusOptions = [
    { value: 'Yes', label: 'Yes' },
    { value: 'No', label: 'No' },
    { value: 'Nearly Full', label: 'Nearly Full' },
  ];

  const customStyles = {
    availableStatus: {
      control: (provided, state) => ({
        ...provided,
      }),
      menuPortal: (provided) => ({
        ...provided,
        zIndex: 9999,
      }),
    },
  };

  const validationSchema = Yup.object().shape({
    name: Yup.string().required('Name is Required'),
    email: Yup.string()
      .email('Please enter valid email address')
      .required('Email is required'),
    about_merchant: Yup.string().required('Bio is required'),
    experience: Yup.string().required('Experience is required'),
    availableStatus: Yup.string().required('Available Status is required'),
    styleProject: Yup.string().required('Style is required'),
    domain: Yup.string().url('Website URL format is not valid.'),
    instagram: Yup.string().url('Instagram URL is not valid.'),
    cp_description: Yup.string().required(
      'Custom project description is required'
    ),
    cp_price_per_m: Yup.string().required('Custom project price is required'),
    benefits: Yup.array().of(
      Yup.object().shape({
        name: Yup.string().required('Promosi is required.'),
      })
    ),
  });

  const formOptions = { resolver: yupResolver(validationSchema) };

  const {
    register,
    handleSubmit,
    setValue,
    control,
    formState: { errors },
    clearErrors,
    unregister,
    trigger,
  } = useForm(formOptions);

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'benefits',
  });

  const isInitialRender = useRef(true);

  const appendBenefit = () => {
    append({
      name: '',
    });

    if (errors.benefits?.type === 'min') {
      clearErrors('questions'); // always clear errors when there is add action.
    }
  };

  useEffect(() => {
    if (!fields.length && !isInitialRender.current) {
      trigger('questions');
    }

    if (isInitialRender.current) {
      isInitialRender.current = false;
    }
  }, [fields, register, setValue, unregister, trigger]);

  const getStyleLists = async () => {
    const response = await getListRoomStyle();
    if (response.message === 'Success') {
      let styleTags = [];
      response?.data.map((val, index) =>
        styleTags.push({
          value: val?.id,
          label: val?.name,
        })
      );
      setStyles(styleTags);
    }
  };

  const handleChangeStyle = (selectedOption) => {
    setStyleSelected(selectedOption);

    // Set value
    let currentValue = [];

    selectedOption.map((option, index) => currentValue.push(option.value));

    if (currentValue?.length > 0) {
      setValue('styleProject', JSON.stringify(currentValue));
    } else {
      setValue('styleProject', '');
    }
  };

  const handleChangeAvailableStatus = (e) => {
    setSelectedAvailableStatus(e);
    setValue('availableStatus', e.value);
  };

  const onSubmitProfile = (data) => {
    setErrorMessage(null);
    setIsSubmitting(true);

    const payload = {
      ...data,
      use_custom_project: true,
      cp_price_per_m: parseInt(String(data.cp_price_per_m).replaceAll('.', '')),
      general_message: dataProfile?.general_message,
      address: dataProfile?.address,
      address_2: dataProfile?.address_2,
      region_id: dataProfile?.region_id,
      district_id: dataProfile?.district_id,
      postal_code: dataProfile?.postal_code,
      phone: dataProfile?.phone,
      wh_address: dataProfile?.wh_address,
      wh_address_2: dataProfile?.wh_address_2,
      wh_region_id: dataProfile?.wh_region_id,
      wh_district_id: dataProfile?.wh_district_id,
      wh_postal_code: dataProfile?.wh_postal_code,
      wh_phone: dataProfile?.wh_phone,
    };

    const loadingToast = toast.loading('Updating profile...');

    updateProfile(payload)
      .then((response) => {
        if (response.message === 'Success') {
          getUserData();
          toast.update(loadingToast, {
            render: 'Profile updated',
            type: 'success',
            isLoading: false,
            autoClose: 5000,
            closeOnClick: true,
            pauseOnHover: true,
          });

          navigate('/designer');
          return;
        } else {
          setErrorMessage(response.message);
          toast.update(loadingToast, {
            render: response.message,
            type: 'error',
            isLoading: false,
            autoClose: 5000,
            closeOnClick: true,
            pauseOnHover: true,
          });
        }
      })
      .finally(() => {
        setIsSubmitting(false);
      });
  };

  useEffect(() => {
    getStyleLists();
  }, []);

  useEffect(() => {
    if (dataProfile) {
      // set selected style
      setValue('name', dataProfile?.name);
      setValue('availableStatus', dataProfile?.availableStatus);
      setSelectedAvailableStatus({
        value: dataProfile?.availableStatus,
        label: dataProfile?.availableStatus,
      });
      setValue('email', dataProfile?.email);
      setValue('about_merchant', dataProfile?.about_merchant);
      setValue('domain', dataProfile?.domain ?? '');
      setValue('instagram', dataProfile?.instagram);
      setValue(
        'styleProject',
        dataProfile?.styleProject === '[]' ||
          dataProfile?.styleProject === null ||
          dataProfile?.styleProject === '' ||
          dataProfile?.styleProject === undefined
          ? ''
          : dataProfile?.styleProject
      );

      // if (dataProfile?.styleProject) {
      //   let parsed = JSON.parse(dataProfile?.styleProject);
      //   let selecteds = [];

      //   parsed.forEach((data) => {
      //     let selectedData = styles.find(
      //       (styleTag) => parseInt(styleTag.value) === parseInt(data)
      //     );
      //     if (selectedData) {
      //       selecteds.push(selectedData);
      //     }
      //   });
      //   setStyleSelected(selecteds);
      // }
      setValue('experience', dataProfile?.experience ?? '');
      setValue('use_custom_project', dataProfile?.use_custom_project);
      setValue('cp_description', dataProfile?.cp_description ?? '');
      setValue('cp_price_per_m', formatMoney(dataProfile?.cp_price_per_m, ''));
    }
  }, [dataProfile]);

  useEffect(() => {
    if (benefits) {
      setValue(
        'benefits',
        benefits.map((benefit) => ({ name: benefit?.name }))
      );
    }
  }, [benefits]);

  useEffect(() => {
    // set selected style
    if (dataProfile?.styleProject && styles.length > 0) {
      let parsed = JSON.parse(dataProfile?.styleProject);
      let selecteds = [];

      parsed.forEach((data, index) => {
        let selectedData = styles.find(
          (styleTag) => parseInt(styleTag.value) === parseInt(data)
        );
        if (selectedData) {
          selecteds.push(selectedData);
        }
      });
      setStyleSelected(selecteds);
    }
  }, [styles]);

  return (
    <form onSubmit={handleSubmit(onSubmitProfile)}>
      <div className="fs-5 fw-bold mb-4">
        Profile Information
        <i className="fa fas-pencil"></i>
      </div>
      {errorMessage !== null && (
        <div className="alert alert-danger alert-dismissible fade show">
          {errorMessage}
          <button
            type="button"
            className="close"
            onClick={() => setErrorMessage(null)}
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
      )}

      <div className="d-sm-flex mb-3">
        <div className="col-12 col-sm-6 mb-3 mb-sm-0 pe-sm-2">
          <label className="form-label d-flex fw-semibold">
            Username<div className="text-danger ps-1">*</div>
          </label>
          <input
            disabled
            type="text"
            className="form-control"
            {...register('name')}
          />
          {errors.name ? (
            <span className="text-danger">{errors.name.message}</span>
          ) : null}
        </div>
        <div className="col-12 col-sm-6 ps-sm-2">
          <label className="form-label d-flex fw-semibold">
            Email<div className="text-danger ps-1">*</div>
          </label>
          <input
            disabled={!isEditProfile}
            type="text"
            className="form-control"
            {...register('email')}
          />
          {errors.email ? (
            <span className="text-danger">{errors.email.message}</span>
          ) : null}
        </div>
      </div>

      <div className="d-sm-flex mb-3">
        <div className="col-12 col-sm-6 mb-3 mb-sm-0 pe-sm-2">
          <label className="form-label d-flex fw-semibold">
            Bio<div className="text-danger ps-1">*</div>
          </label>
          <textarea
            disabled={!isEditProfile}
            className="form-control"
            rows="3"
            {...register('about_merchant')}
          ></textarea>
          {errors.about_merchant ? (
            <span className="text-danger">{errors.about_merchant.message}</span>
          ) : null}
        </div>
        <div className="col-12 col-sm-6 ps-sm-2">
          <label className="form-label d-flex fw-semibold">
            Experience (Years)<div className="text-danger ps-1">*</div>
          </label>
          <input
            disabled={!isEditProfile}
            type="number"
            className="form-control"
            {...register('experience')}
            min={0}
          />
          {errors.experience ? (
            <span className="text-danger">{errors.experience.message}</span>
          ) : null}
        </div>
      </div>

      <div className="d-sm-flex mb-3">
        <div className="col-12 col-sm-6 mb-3 mb-sm-0 pe-sm-2">
          <label className="form-label d-flex fw-semibold">
            Available Status<div className="text-danger ps-1">*</div>
          </label>
          <Controller
            name="availableStatus"
            control={control}
            render={({ field }) => (
              <Select
                className="basic-single"
                classNamePrefix="select"
                value={selectedAvailableStatus}
                placeholder="Select Status"
                options={availableStatusOptions}
                isSearchable={true}
                styles={customStyles.availableStatus}
                menuPortalTarget={document.body}
                onChange={handleChangeAvailableStatus}
              />
            )}
          />

          {errors.availableStatus ? (
            <span className="text-danger">
              {errors.availableStatus.message}
            </span>
          ) : null}
        </div>
        <div className="col-12 col-sm-6 ps-sm-2">
          <label className="form-label d-flex fw-semibold">Website (URL)</label>
          <input
            disabled={!isEditProfile}
            type="text"
            className="form-control"
            {...register('domain')}
            placeholder="e.g: https://www.google.com"
          />
          {errors.domain ? (
            <span className="text-danger">{errors.domain.message}</span>
          ) : null}
        </div>
      </div>

      <div className="d-sm-flex mb-3">
        <div className="col-12 col-sm-6 mb-3 mb-sm-0 pe-sm-2">
          <label className="form-label d-flex fw-semibold">
            Instagram Profile (URL)
          </label>
          <input
            disabled={!isEditProfile}
            type="text"
            className="form-control"
            {...register('instagram')}
          />
          {errors.instagram ? (
            <span className="text-danger">{errors.instagram.message}</span>
          ) : null}
        </div>
      </div>

      <div className="d-sm-flex mb-3">
        <div className="col-12 col-sm-6 mb-3 mb-sm-0 pe-sm-2">
          <label className="form-label d-flex fw-semibold">
            Style<div className="text-danger ps-1">*</div>
          </label>
          <Select
            isMulti
            options={styles}
            value={styleSelected}
            onChange={handleChangeStyle}
            menuPlacement="auto"
          />
          {errors.styleProject ? (
            <span className="text-danger">{errors.styleProject.message}</span>
          ) : null}
        </div>
      </div>

      <div className="fs-5 fw-bold mt-5 mb-4">Custom Project</div>

      <div className="d-sm-flex mb-3">
        <div className="col-12 col-sm-6 mb-3 mb-sm-0 pe-sm-2">
          <div className="mb-3" key="cp-1">
            <label className="form-label d-flex fw-semibold">
              Price (Per Meter)
              <div className="text-danger ps-1">*</div>
            </label>
            <input
              {...register('cp_price_per_m')}
              type="text"
              className="form-control"
              onKeyUp={(event) =>
                setValue(
                  'cp_price_per_m',
                  formatMoney(event.target.value.replaceAll('.', ''), '')
                )
              }
            />
            {errors.cp_price_per_m ? (
              <span className="text-danger">
                {errors.cp_price_per_m.message}
              </span>
            ) : null}
          </div>

          <div className="mb-3" key="cp-2">
            <label className="form-label d-flex fw-semibold">
              Description<div className="text-danger ps-1">*</div>
            </label>
            <textarea
              className="form-control"
              rows="3"
              {...register('cp_description')}
            ></textarea>
            {errors.cp_description ? (
              <span className="text-danger">
                {errors.cp_description.message}
              </span>
            ) : null}
          </div>

          <div className="mb-3" key="cp-3">
            <div className="d-flex flex-column mb-3">
              <div className="d-flex flex-row align-items-center justify-content-between">
                <label className="form-label d-flex fw-semibold m-0">
                  Promotion
                </label>

                <button
                  type="button"
                  className="btn btn-primary btn-sm"
                  onClick={appendBenefit}
                >
                  <i className="fas fa-plus"></i>
                </button>
              </div>

              {errors.benefits ? (
                <span className="text-danger">{errors.benefits.message}</span>
              ) : null}
            </div>
            {fields.map((benefit, benefitIndex) => (
              <div key={benefit.id} className="mb-3">
                <div className="d-flex flex-row">
                  <input
                    type="text"
                    className="form-control"
                    {...register(`benefits[${benefitIndex}].name`)}
                    control={control}
                  />
                  <div
                    className="btn-group ms-3"
                    role="group"
                    aria-label="benefit-action-button-group"
                  >
                    <button
                      type="button"
                      className="btn btn-danger btn-sm"
                      style={{ width: '45px' }}
                      onClick={() => {
                        remove(benefitIndex);
                        trigger();
                      }}
                    >
                      <i className="fas fa-trash"></i>
                    </button>
                  </div>
                </div>
                {errors?.benefits?.[benefitIndex]?.name ? (
                  <span className="text-danger">
                    {errors?.benefits?.[benefitIndex]?.name?.message}
                  </span>
                ) : null}
              </div>
            ))}
          </div>
        </div>
      </div>

      <div className="d-flex justify-content-end">
        {!isSubmitting && (
          <div className="col-12 col-sm-4 ps-sm-2 d-flex justify-content-end">
            <button
              type="submit"
              className="btn w-100 fw-semibold btn-fill px-5 mt-3 ask"
            >
              Save
            </button>
          </div>
        )}
        {isSubmitting && (
          <button
            type="submit"
            disabled
            className="btn w-sm-100 m-0 m-sm-1 btn-fill fw-semibold"
          >
            <div
              className="spinner-border"
              role="status"
              style={{ width: '1.5rem', height: '1.5rem' }}
            >
              <span className="sr-only">Loading...</span>
            </div>
          </button>
        )}
      </div>
    </form>
  );
};

export default ProfileDesigner;
